import { useEffect } from 'react';

export default function usePreventPrint() {
  useEffect(() => {
    const beforePrint = () => {
      document.body.style.display = 'none';
    };

    const afterPrint = () => {
      document.body.style.display = 'block';
    };

    window.addEventListener('beforeprint', beforePrint);
    window.addEventListener('afterprint', afterPrint);

    return () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);
}
